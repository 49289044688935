
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as test4nxtARjU2tgraxs13wdS5c0NNj4Lax6En6LJJYLfrcoMeta } from "/code/pages/test.vue?macro=true";
import { default as indexBPDLIX4zlXeHeja7L5OarPeljL0YORAEa4gCQd3doR8Meta } from "/code/pages/index.vue?macro=true";
import { default as login9BRy9ZU7Xia2frHb0HxlIsyNbJZBb_45slGTgFwaZqdw8Meta } from "/code/pages/login.vue?macro=true";
import { default as signupP28E5NzcPrLAkUe1ZdKSPbOKGurnhzJxT_vPdIABD5cMeta } from "/code/pages/signup.vue?macro=true";
import { default as launchiUPWIbqkCYLm8N2mIgzs6I_ZVpTW4_4_45yKrchXhYussMeta } from "/code/pages/smart/launch.vue?macro=true";
import { default as reset_45passwordvwXS3yhTPoLgPrdwNX2Ns59KTM4TaXhc2IpDlYmr_45YkMeta } from "/code/pages/reset-password.vue?macro=true";
import { default as callback1f0w4nAFN5SYcdJtixidTDox_45ez3SVRDWRnxUEgaI7oMeta } from "/code/pages/smart/callback.vue?macro=true";
import { default as forgot_45passwordPhO1FNqi4fBi3_45S62QDTG4_LPRK268m0CeKvIPWYvEcMeta } from "/code/pages/forgot-password.vue?macro=true";
import { default as request_45patientL3yN7AjJbRcJgctEszn_45Va41cJNZxfe4moEfFaesPEkMeta } from "/code/pages/request-patient.vue?macro=true";
import { default as _91patientId_93NmcWGvn7V5bd8VWCSXM1LZatATgcUEEloBsJMCb1_45AYMeta } from "/code/pages/no-records/[patientId].vue?macro=true";
import { default as _91patientId_93oKnC0ThhiUaMjNH8TPOSte_NQ7e5i300_45iIkqbDdaIgMeta } from "/code/pages/audit-summary/[patientId].vue?macro=true";
import { default as labsaHzd8jeAI6Thm9zwb_452p4_45hC8eFbhlTf_45yPOQzfh8DAMeta } from "/code/pages/patients/[patientId]/index/labs.vue?macro=true";
import { default as chartsAFK3FyQClXtr7ED94xhP1mJ1KyauvnrM8pKTMlb2DY8Meta } from "/code/pages/patients/[patientId]/index/charts.vue?macro=true";
import { default as vitalsHp2kgnj7yKaxkYs9Oqv0cXmxKq8iMEX1Ht4A0r_451ZrgMeta } from "/code/pages/patients/[patientId]/index/vitals.vue?macro=true";
import { default as medicationspbB0FITRyh2rDz9c4H7Mz2pi87ht4uE5a2_45BPbfYcYoMeta } from "/code/pages/patients/[patientId]/index/medications.vue?macro=true";
import { default as indexrTC72toOgWLblnLI3cLoPsancKpNyb8_450D38PnK7c2QMeta } from "/code/pages/patients/[patientId]/index.vue?macro=true";
import { default as labsq3yu0S_451oZ1z__DZsQpGIZ_45_45vrJYZuyBnGZYwF6Fs_45MMeta } from "/code/pages/patient/[patientId]/index/labs.vue?macro=true";
import { default as _91patientId_93duV_45MSqG0R0Cf3BmUYwUDL_9zJN8YJ6P9vit4HlsfU0Meta } from "/code/pages/credo-encounter-data/[patientId].vue?macro=true";
import { default as charts2wYKhc2FndNeMEewx2H5Mk51ilF3YXUpGfPig7aXCecMeta } from "/code/pages/patient/[patientId]/index/charts.vue?macro=true";
import { default as vitals0CCCxPBW_45nz_52Ug9cuY5a1HGCkVtdL1vpj02nsU33oMeta } from "/code/pages/patient/[patientId]/index/vitals.vue?macro=true";
import { default as labsAkm1_45CKh06JVM4vDBaxI7T1QJXlBsoSMjoIq14IrQ1QMeta } from "/code/pages/patient/[patientId]/patient/labs.vue?macro=true";
import { default as charts1Ej_rrpQD1qGiW1DES5U0WBFrmC_qm5CGeI448ao66kMeta } from "/code/pages/patient/[patientId]/patient/charts.vue?macro=true";
import { default as vitalsobN_QUbTeh2y0789dgyGl5MaDrfCUFocMHiCtT9pVbcMeta } from "/code/pages/patient/[patientId]/patient/vitals.vue?macro=true";
import { default as medicationsa1YwIJ6Aima_45HOzFyJao8qhO3k47zbiHZA9let3EFGIMeta } from "/code/pages/patient/[patientId]/index/medications.vue?macro=true";
import { default as medicationsnFRRPARPzkF31AeVmyE_dKjVdhFRMUjR3Cbr4G850m0Meta } from "/code/pages/patient/[patientId]/patient/medications.vue?macro=true";
import { default as _91fileId_93_lWnse47VQE1ejqjrj3ciOaFQnonTdb0FZN1iEPUPi0Meta } from "/code/pages/patients/[patientId]/pdf-viewer/[fileId].vue?macro=true";
export default [
  {
    name: "test",
    path: "/test",
    meta: test4nxtARjU2tgraxs13wdS5c0NNj4Lax6En6LJJYLfrcoMeta || {},
    component: () => import("/code/pages/test.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/code/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: login9BRy9ZU7Xia2frHb0HxlIsyNbJZBb_45slGTgFwaZqdw8Meta || {},
    component: () => import("/code/pages/login.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupP28E5NzcPrLAkUe1ZdKSPbOKGurnhzJxT_vPdIABD5cMeta || {},
    component: () => import("/code/pages/signup.vue")
  },
  {
    name: "smart-launch",
    path: "/smart/launch",
    meta: launchiUPWIbqkCYLm8N2mIgzs6I_ZVpTW4_4_45yKrchXhYussMeta || {},
    component: () => import("/code/pages/smart/launch.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordvwXS3yhTPoLgPrdwNX2Ns59KTM4TaXhc2IpDlYmr_45YkMeta || {},
    component: () => import("/code/pages/reset-password.vue")
  },
  {
    name: "smart-callback",
    path: "/smart/callback",
    meta: callback1f0w4nAFN5SYcdJtixidTDox_45ez3SVRDWRnxUEgaI7oMeta || {},
    component: () => import("/code/pages/smart/callback.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordPhO1FNqi4fBi3_45S62QDTG4_LPRK268m0CeKvIPWYvEcMeta || {},
    component: () => import("/code/pages/forgot-password.vue")
  },
  {
    name: "request-patient",
    path: "/request-patient",
    component: () => import("/code/pages/request-patient.vue")
  },
  {
    name: "no-records-patientId",
    path: "/no-records/:patientId()",
    meta: _91patientId_93NmcWGvn7V5bd8VWCSXM1LZatATgcUEEloBsJMCb1_45AYMeta || {},
    component: () => import("/code/pages/no-records/[patientId].vue")
  },
  {
    name: "audit-summary-patientId",
    path: "/audit-summary/:patientId()",
    meta: _91patientId_93oKnC0ThhiUaMjNH8TPOSte_NQ7e5i300_45iIkqbDdaIgMeta || {},
    component: () => import("/code/pages/audit-summary/[patientId].vue")
  },
  {
    name: "patients-patientId",
    path: "/patients/:patientId()",
    meta: indexrTC72toOgWLblnLI3cLoPsancKpNyb8_450D38PnK7c2QMeta || {},
    component: () => import("/code/pages/patients/[patientId]/index.vue"),
    children: [
  {
    name: "patients-patientId-index-labs",
    path: "labs",
    meta: labsaHzd8jeAI6Thm9zwb_452p4_45hC8eFbhlTf_45yPOQzfh8DAMeta || {},
    component: () => import("/code/pages/patients/[patientId]/index/labs.vue")
  },
  {
    name: "patients-patientId-index-charts",
    path: "charts",
    meta: chartsAFK3FyQClXtr7ED94xhP1mJ1KyauvnrM8pKTMlb2DY8Meta || {},
    component: () => import("/code/pages/patients/[patientId]/index/charts.vue")
  },
  {
    name: "patients-patientId-index-vitals",
    path: "vitals",
    meta: vitalsHp2kgnj7yKaxkYs9Oqv0cXmxKq8iMEX1Ht4A0r_451ZrgMeta || {},
    component: () => import("/code/pages/patients/[patientId]/index/vitals.vue")
  },
  {
    name: "patients-patientId-index-medications",
    path: "medications",
    meta: medicationspbB0FITRyh2rDz9c4H7Mz2pi87ht4uE5a2_45BPbfYcYoMeta || {},
    component: () => import("/code/pages/patients/[patientId]/index/medications.vue")
  }
]
  },
  {
    name: "patient-patientId-index-labs",
    path: "/patient/:patientId()/labs",
    component: () => import("/code/pages/patient/[patientId]/index/labs.vue")
  },
  {
    name: "credo-encounter-data-patientId",
    path: "/credo-encounter-data/:patientId()",
    meta: _91patientId_93duV_45MSqG0R0Cf3BmUYwUDL_9zJN8YJ6P9vit4HlsfU0Meta || {},
    component: () => import("/code/pages/credo-encounter-data/[patientId].vue")
  },
  {
    name: "patient-patientId-index-charts",
    path: "/patient/:patientId()/charts",
    component: () => import("/code/pages/patient/[patientId]/index/charts.vue")
  },
  {
    name: "patient-patientId-index-vitals",
    path: "/patient/:patientId()/vitals",
    component: () => import("/code/pages/patient/[patientId]/index/vitals.vue")
  },
  {
    name: "patient-patientId-patient-labs",
    path: "/patient/:patientId()/patient/labs",
    component: () => import("/code/pages/patient/[patientId]/patient/labs.vue")
  },
  {
    name: "patient-patientId-patient-charts",
    path: "/patient/:patientId()/patient/charts",
    component: () => import("/code/pages/patient/[patientId]/patient/charts.vue")
  },
  {
    name: "patient-patientId-patient-vitals",
    path: "/patient/:patientId()/patient/vitals",
    component: () => import("/code/pages/patient/[patientId]/patient/vitals.vue")
  },
  {
    name: "patient-patientId-index-medications",
    path: "/patient/:patientId()/medications",
    component: () => import("/code/pages/patient/[patientId]/index/medications.vue")
  },
  {
    name: "patient-patientId-patient-medications",
    path: "/patient/:patientId()/patient/medications",
    component: () => import("/code/pages/patient/[patientId]/patient/medications.vue")
  },
  {
    name: "patients-patientId-pdf-viewer-fileId",
    path: "/patients/:patientId()/pdf-viewer/:fileId()",
    meta: _91fileId_93_lWnse47VQE1ejqjrj3ciOaFQnonTdb0FZN1iEPUPi0Meta || {},
    component: () => import("/code/pages/patients/[patientId]/pdf-viewer/[fileId].vue")
  }
]