import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/code/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/code/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/code/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/code/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/code/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/code/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/code/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/code/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/code/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import sentry_client_Tkghm6qboZyK83jq3ydlDUVT7O0Cb00E6XcepuVj9_c from "/code/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag from "/code/.nuxt/sentry-client-config.mjs";
import primevue_plugin_0tbz5Y3Ab_H50qf9PQhqyN3k7XWjmG9dWGLJNwEfVI0 from "/code/.nuxt/primevue-plugin.mjs";
import plugin_client_wp4kxkdwIclITZVcWkXPniTgFVTtTwZamTFNo05D70w from "/code/node_modules/@primevue/nuxt-module/dist/runtime/plugin.client.js";
import plugin_jbke4bdVryj9IfF8COve2XEqjrseGxu_7wRSgSmELfY from "/code/node_modules/mixpanel-nuxt/dist/runtime/plugin.js";
import plugin_xp_hzh2CmddJ3Myd_cL2a86QZnSMAE8MuLZ6iluDhtI from "/code/node_modules/@sidebase/nuxt-auth/dist/runtime/plugin.js";
import mixpanel_pageview_tracker_YmJX_e3lJSugWYVkawhXYUIg63VPc5SKsBuD_Gmuz5k from "/code/plugins/mixpanel-pageview-tracker.ts";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  sentry_client_Tkghm6qboZyK83jq3ydlDUVT7O0Cb00E6XcepuVj9_c,
  sentry_client_config_jWSZGY3AZiojeM3FtOet57G_x4XiATyA4Ng46mi7Tag,
  primevue_plugin_0tbz5Y3Ab_H50qf9PQhqyN3k7XWjmG9dWGLJNwEfVI0,
  plugin_client_wp4kxkdwIclITZVcWkXPniTgFVTtTwZamTFNo05D70w,
  plugin_jbke4bdVryj9IfF8COve2XEqjrseGxu_7wRSgSmELfY,
  plugin_xp_hzh2CmddJ3Myd_cL2a86QZnSMAE8MuLZ6iluDhtI,
  mixpanel_pageview_tracker_YmJX_e3lJSugWYVkawhXYUIg63VPc5SKsBuD_Gmuz5k
]